// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/app';
//import firestore from 'firebase';
import 'firebase/firestore'
//import de l'authentification 
import 'firebase/auth'
//import du storage
import 'firebase/storage'
//import messaging
//import 'firebase/messaging'

const firebaseConfig = {
    apiKey: "AIzaSyC3j1hdAr6Gh1DdGTp5Tb8tQpsRfP3UGYk",
    authDomain: "vue-firebase-ionic.firebaseapp.com",
    projectId: "vue-firebase-ionic",
    storageBucket: "vue-firebase-ionic.appspot.com",
    messagingSenderId: "178538354219",
    appId: "1:178538354219:web:75d769e359718e025cbc6e",
    measurementId: "G-PE6S7TK2T8"
};

// init firebase
firebase.initializeApp(firebaseConfig);

//init service 
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth()
const projectStorage = firebase.storage();
//init timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp
//const projectMessaging = firebase.messaging()

export { projectAuth, projectFirestore, projectStorage, timestamp }