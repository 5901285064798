import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config.ts'

const getCollection = (collection, order) => {

    const documents = ref(null)
    const error = ref(null)
        //console.log()
        // register the firestore collection reference
    const collectionRef = projectFirestore.collection(collection)
        .orderBy(order)

    const unsub = collectionRef.onSnapshot(snap => {
        const results = []
        snap.docs.forEach(doc => {
            // must wait for the server to create the timestamp & send it back
            doc.data().createdAt && results.push({...doc.data(), id: doc.id })
        });

        // update values
        documents.value = results
            //console.log(results)
        error.value = null
    }, err => {
        console.log(err.message)
        documents.value = null
        error.value = 'could not fetch the data'
    })

    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub());
    });

    return { error, documents }
}

export default getCollection