import { createStore, createLogger } from 'vuex'
import { projectAuth } from './firebase/config';






//interface
interface UploadAndSaveParams {
    image: string;
    subject: string;
    body: string;
}
//init timestamp
// const timestamp = firebase.firestore.FieldValue.serverTimestamp ;

//Firebase
// const uploadAndSave = async( {image,subject,body}: UploadAndSaveParams )=>{
//         //console.log(image);
// };



// const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyC3j1hdAr6Gh1DdGTp5Tb8tQpsRfP3UGYk",
//     authDomain: "vue-firebase-ionic.firebaseapp.com",
//     projectId: "vue-firebase-ionic",
//     storageBucket: "vue-firebase-ionic.appspot.com",
//     messagingSenderId: "178538354219",
//     appId: "1:178538354219:web:75d769e359718e025cbc6e",
//     measurementId: "G-PE6S7TK2T8"
// };

const store = createStore({
    plugins: [createLogger()],
    state: {
        //essai 28/10/2021
        blank:true,
        //
        user: {},
        initialized: false,
        day: "",
        hour: "",
        total: "",
        searchPatient: {},
        collectionRdv: {},
        styleLoading: {
            position: "absolute",
            color: "black",
            width: "100%",
            backgroundColor: "white",
            height: "100%",
            zIndex: "11111111111111111111111111",
            top: 0,
            left: 0,
        }
    },
    actions: {
        async login(ctx: any, { email, password }) {
            await projectAuth.signInWithEmailAndPassword(email, password)
            const user = projectAuth.currentUser
            ctx.commit('setAuthenticatedUser', { user })
            //console.log(user)
            return true
        },
        async logout(ctx: any) {
            await projectAuth.signOut()
            ctx.commit('setAuthenticatedUser', { user: null })
            return true
        },
        initializeStore(ctx: any) {
            return new Promise((resolve) => {
                //console.log('dans le store initialisé')

                projectAuth.onAuthStateChanged(() => {
                    const user = projectAuth.currentUser
                    //console.log(user)
                    ctx.commit('initialized', { initialized: false, user })
                })
                resolve(true)
            })
        },
        getCollection(ctx: any,documents){
            console.log(ctx)
            //const { documents } = getCollection("patients", "total");
            console.log(documents)
            ctx.commit('setCollectionPatient',documents)

        },


    },
    mutations: {
        setCollectionPatient(state: any, payload: any) {
            state.collectionRdv = payload
        },
        setSearchPatient(state: any, payload: any) {
            state.searchPatient = payload
        },
        setDay(state: any, payload: any) {
            state.day = payload;
        },
        setHour(state: any, payload: any) {
            state.hour = payload;
            state.total = state.day + " " + state.hour.hours + " " + state.hour.minutes;
            //console.log(state.total)
        },
        setAuthenticatedUser(state: any, payload) {
            //console.log(payload);
            state.user = payload.user;
            //console.log(state.user);
        },
        initialized(state: any, payload: any) {
            //console.log("initialized");
            state.user = payload.user;
            state.initialized = payload.initialized;
        },
        setBlank(state: any,payload: any){
            state.blank = payload.blank;
        }
    }
})

export default store


