<template>
  <IonApp>
    <IonSplitPane  content-id="main-content">

      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>Rémi PRADERE</ion-list-header>
            <ion-note>Podologue</ion-note>
            <ion-menu-toggle style="color: white" auto-hide="false">
          
           
              <ion-item
                v-show="accueilButtonShow == true"
                @click="clickAccueil"
                router-direction="root"
                :router-link="appPages[0].url"
                lines="none"
                detail="false"
                class="hydrated"
                :class="{ selected: selectedIndex === 0 }"
                style='cursor:pointer;'            
              >
                <ion-icon
                  v-if="appPages[0].title == 'Inbox'"
                  slot="start"
                  :ios="appPages[0].iosIcon"
                  :md="appPages[0].mdIcon"
                ></ion-icon>
                <ion-label v-if="appPages[0].title == 'Inbox'"
                  >Accueil</ion-label
                >
              </ion-item>  
                <div class="loader" v-show="accueilButtonShow.value == false"> loading </div>
              <template v-if="!userM">
                <ion-item
                  @click="selectedIndex = 1"
                  router-direction="root"
                  :router-link="appPages[1].url"
                  lines="none"
                  detail="false"
                  class="hydrated"
                  :class="{ selected: selectedIndex === 1 }"
                >
                  <ion-icon
                    v-if="appPages[1].title == 'RdvSansConnexion'"
                    slot="start"
                    :ios="appPages[1].iosIcon"
                    :md="appPages[1].mdIcon"
                  ></ion-icon>
                  <ion-label v-if="appPages[1].title == 'RdvSansConnexion'"
                    >Rdv sans connexion</ion-label
                  >
                </ion-item>
              </template>
              <template v-if="userM">
                <ion-item
                  @click="selectedIndex = 2"
                  router-direction="root"
                  :router-link="appPages[2].url"
                  lines="none"
                  detail="false"
                  class="hydrated"
                  :class="{ selected: selectedIndex === 2 }"
                >
                  <ion-icon
                    v-if="appPages[2].title == 'Horaires'"
                    slot="start"
                    :ios="appPages[2].iosIcon"
                    :md="appPages[2].mdIcon"
                  ></ion-icon>
                  <ion-label v-if="appPages[2].title == 'Horaires'"
                    >Prendre Rdv</ion-label
                  >
                </ion-item>
              </template>
              <template>
                <ion-item
                  @click="selectedIndex = 3"
                  router-direction="root"
                  :router-link="appPages[3].url"
                  lines="none"
                  detail="false"
                  class="hydrated"
                  :class="{ selected: selectedIndex === 3 }"
                >
                  <ion-icon
                    v-if="appPages[3].title == 'Agenda'"
                    slot="start"
                    :ios="appPages[3].iosIcon"
                    :md="appPages[3].mdIcon"
                  ></ion-icon>
                  <ion-label v-if="appPages[3].title == 'Agenda'"
                    >Agenda</ion-label
                  >
                </ion-item>
              </template>
            </ion-menu-toggle>
          </ion-list>

          <ion-list id="labels-list">
            <ion-list-header v-if="user != 'remipradere@gmail.com'"
              >Votre prochain rendez vous :
            </ion-list-header>
            {{ user }}
            <ion-item>
              <ion-label>{{ label }}</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-menu>
      
      
      <ion-router-outlet id="main-content"></ion-router-outlet>
      
    </IonSplitPane>
  </IonApp>
</template>

<script>
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
} from "ionicons/icons";
import { useStore } from "vuex";
import getCollection from "@/composables/getCollection";


// import { OneSignal } from '@ionic-native/onesignal';
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    //boolean pour le bouton montrer show 
    const accueilButtonShow = ref(true);
    
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "Inbox",
        url: "/folder/Inbox",
        iosIcon: mailOutline,
        mdIcon: mailSharp,
      },
      {
        title: "RdvSansConnexion",
        url: "/folder/rdvsansconnexion",
        iosIcon: heartOutline,
        mdIcon: heartSharp,
      },
      {
        title: "Horaires",
        url: "/folder/horaires",
        iosIcon: heartOutline,
        mdIcon: heartSharp,
      },
      {
        title: "Agenda",
        url: "/folder/agenda",
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
      },
    ];
    const labels = ["Family", "Friends"];
    const { documents } = getCollection("patients", "total");
    const referenceRdv = ref("");
    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    const clickAccueil = () => {
        selectedIndex.value = 0;
         accueilButtonShow.value = false;
        setTimeout(()=>{
          accueilButtonShow.value = true;
          console.log(accueilButtonShow)},1000)
    }




    const onesignal = () => {
      console.log(window.OneSignal)
      const OneSignal = window['OneSignal'] || [];
      console.log(OneSignal);
      OneSignal.push([
        "init",
        {
          //appId: "5fa39291-98eb-4178-a023-bf8619d59f96",
          //cle online
          appId: "6b1b528b-a740-4d1f-bd91-744c36a7b567",
          
          autoRegister: true,
          promptOptions: {
            slidedown: {
              prompts: [
                {
                  type: 'push', // current types are "push" & "category"
                  autoPrompt: true,
                  text: {
                    /* limited to 90 characters */
                    actionMessage: 'We d like to show you notifications for the latest news and updates.',
                    /* acceptButton limited to 15 characters */
                    acceptButton: 'Allow',
                    /* cancelButton limited to 15 characters */
                    cancelButton: 'Cancel'
                  },
                  delay: {
                    pageViews: 1,
                    timeDelay: 20
                  }
                }
              ]
            }
          },
        },
      ]);
      //console.log("OneSignal Initialized");
      OneSignal.push(function () {
        OneSignal.getUserId(function (userId) {
          console.log("OneSignal User ID:", userId);
        
        });
      });
    };

    const route = useRoute();
    const store = useStore();
    onMounted(async () => {
      await store.dispatch("initializeStore");
      onesignal();
    });

    const user = ref("");
    const userM = computed(() => {
      return store.state.user;
    });
    const userEmail = computed(() => {
      return store.state.user.email;
    });
    setTimeout(() => {
      user.value = store.state.user.email;
      documents.value.forEach((element) => {
        if (element.email == user.value) {
          referenceRdv.value = element;
          // //console.log(referenceRdv.value);
        }
      });
    }, 3000);
    setTimeout(() => {
      store.commit("setCollectionPatient", documents.value);
      //console.log("500", documents.value);
    }, 2000);
   

    return {
      clickAccueil,
      accueilButtonShow,
      user,
      userEmail,
      userM,
      selectedIndex,
      appPages,
      labels,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      isSelected: (url) => (url === route.path ? "selected" : ""),
    };
  },
});
</script>

<style scoped>
ion-label {
  cursor: pointer;
}
ion-menu ion-content {
  --background: var(--ion-color-primary);
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
  background-color: rgba(var(--ion-color-primary));
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}
.hello {
  color: white !important;
}
ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}
ion-list-header {
  color: var(--ion-color-primary-contrast);
}
.button-inner {
  color: var(ion-color-primary-contrast);
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: var(--ion-color-primary-contrast);

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: var(--ion-color-primary-contrast);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: var(ion-color-primary);
}

ion-menu-toggle ion-item ion-icon {
  color: var(ion-color-primary-contrast);
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: var(ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-primary-contrast);
}
ion-item:active {
  --color: var(--ion-color-primary);
  --background:var(--ion-color-primary-contrast)
}
ion-item.selected {
  --color: var(--ion-color-primary);
}
ion-item.hello {
  color: white !important;
}

ion-menu-toggle {
  --color: var(--ion-color-primary-contrast);
}

.button-native {
  color: white !important;
}

.active {
  position: absolute;
  color:black;
    width: 100%;
    background-color: white;
    height: 100%;
    z-index: 1111111111111111;
}

.loader {
  top:0px;
  left:0px;
  position: fixed;
  width:100%;
  height:800px;
  background-color:pink;
  z-index:1232222222222222222222222222222222222222
}
</style>